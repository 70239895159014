<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <div class="page">
          <div class="page__title">{{ displayTitle }}</div>

          <div class="s-edit-section">
            <div class="mb-2">
              <div class="d-flex justify-content-between">
                <SharedButton
                  v-if="!croppingImage"
                  class="s-btn-bg-primary mb-2 mr-2"
                  @click="openFileSelector"
                  :disabled="!canClick"
                >
                  選取照片
                </SharedButton>

                <SharedButton
                  v-if="!croppingImage"
                  class="s-btn-bg-primary mb-2 mr-2"
                  @click="openCameraSelector"
                  :disabled="!canClick"
                >
                  拍照上傳
                </SharedButton>

                <SharedButton
                  v-if="!croppingImage"
                  class="s-btn-bg-primary mb-2"
                  @click="() => openWebCam = true"
                >
                  立即拍照
                </SharedButton>
                <WebCamDialog
                  v-if="openWebCam"
                  title="圖片加框框"
                  :reminder="{
                    title: '秘訣',
                    text: '挑選一張你最愛的度假風照片上傳或開啟相機來自拍吧！打造如同 GUCCI LIDO 形象廣告裡所營造徜徉水中自在快樂地氛圍。'
                  }"
                  @close="() => openWebCam = false"
                  @snap="(imageBase64) => loadImageWebCam(imageBase64)"
                 />
              </div>

              <SharedButton
                v-if="croppingImage"
                class="s-btn-bg-secondary mb-2"
                @click="resetCropping"
                :disabled="!canClick"
              >
                取消
              </SharedButton>
              <SharedButton
                v-if="croppingImage"
                class="s-btn-bg-primary mb-2"
                @click="cropImage"
                :disabled="!canClick"
              >
                裁切
              </SharedButton>
            </div>
            <!-- <div v-if="selectedProductImage">
                <div class="s-cropper-inner">
                  <img
                    v-if="(selectedProductImageIndex === 0 && selectedProductImage.mask_image_url)"
                    :src="selectedProductImage.mask_image_url"
                    style="position: absolute; max-width: calc(100% - 2rem);"
                  />
                  <img :src="selectedProductImage.pic_url" style="max-width: 100%;" />
                </div>
              </div> -->

            <div
              v-if="croppingImage"
              class="s-edit-section-cropper"
              style="width: 100%"
            >
              <VueCropper
                class="sr-only"
                :src="croppingImage"
                :aspect-ratio="aspectRatio"
                :initialAspectRatio="aspectRatio"
                ref="cropper"
              />
              <img v-if="croppingImage" :id="randomId" :src="croppingImage" />
            </div>

            <div v-if="displayImage" style="width: 100%">
              <img :src="displayImage" />
            </div>
          </div>
          <input
            class="s-file-input"
            type="file"
            name="image"
            accept="image/*"
            ref="input"
            @change="loadImage"
            :multiple="false"
          />
          <input
            class="s-file-input"
            type="file"
            capture="environment"
            accept="image/*"
            ref="camera"
            @change="loadImage"
            :multiple="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import "croppr/dist/croppr.css";
import "cropperjs/dist/cropper.css";
import Croppr from "croppr";
import VueCropper from "vue-cropperjs";
import themeColor from "@/mixins/liff/themeColor";
import { mapGetters } from "vuex";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import WebCamDialog from "./WebCamDialog.vue";
import gamesApi from "@/apis/liff/v2/games";

Croppr.prototype.attachOverlayEvents = function () {}; // 這段負責用來解決『點了截圖畫面，框框會亂跑』的問題

export default {
  mixins: [themeColor],
  components: {
    VueCropper,
    SharedButton,
    WebCamDialog,
  },
  computed: {
    ...mapGetters("liffGeneral", ["themeConfig", "meta"]),
    displayTitle() {
      return this.event?.name ?? "圖片加框框";
    },
  },
  data() {
    return {
      themeConfigPage: "photoFrame",
      randomId: null,
      uploadedImage: null,
      croppingImage: null,
      aspectRatio: 1 / 1, // 裁切比例,
      selectedMask:
        "https://storage.googleapis.com/waltily-dev/temp/2023_banner-1.png",
      canClick: true,
      croppr: null,
      displayImage: null,
      event: null,
      openWebCam: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (this.$route.params.event_code) {
        try {
          let response = await gamesApi.getEvent({
            event_code: this.$route.params.event_code,
          });

          this.event = response.data.data;
          this.selectedMask = this.event.config.mask_image_url;
        } catch (error) {
          console.error(error);
          this.$swal("找不到活動", "", "error");
        }
      }
    },
    async loadImage(event) {
      let file = event.target.files[0];
      this.randomId = `image-${uuidv4()}`;

      await this.$nextTick();

      const reader = new FileReader();

      reader.onload = async (event) => {
        this.displayImage = null;
        this.uploadedImage = event.target.result;
        this.croppingImage = event.target.result;
        this.$refs.input.value = null;
        await this.$nextTick();
        this.$refs.cropper.replace(this.croppingImage);
        this.$nextTick(this.initCroppr);
        this.$nextTick(this.coverMask);
      };

      reader.readAsDataURL(file);
    },
    async loadImageWebCam(imageBase64) {
      this.randomId = `image-${uuidv4()}`;
      await this.$nextTick();
      this.displayImage = null;
      this.uploadedImage = imageBase64;
      this.croppingImage = imageBase64;
      await this.$nextTick();
      this.$refs.cropper.replace(this.croppingImage);
      this.$nextTick(this.initCroppr);
      this.$nextTick(this.coverMask);
    },
    // 初始化 cropper
    initCroppr() {
      let _this = this;

      // cropper 跟 vue-cropperjs 的比例設定剛好顛倒
      const options = {
        aspectRatio: 1 / this.aspectRatio,
        startSize: [100, 100, "%"],
        maxSize: [1024, 1024, "px"],
        minSize: [100, 100, "px"],
        onCropMove: function () {
          _this.coverMask();
        },
        onInitialize: function () {
          _this.coverMask();
        },
      };

      this.croppr = new Croppr(`#${this.randomId}`, options);
    },
    // 覆蓋遮罩
    coverMask() {
      let overlay = document.getElementsByClassName("croppr-region");
      if (overlay.length > 0) {
        overlay = overlay[0];
        if (this.selectedMask !== null) {
          overlay.style.cssText = `
            background-image: url("${this.selectedMask}");
            background-size: contain;
          `;
        } else {
          overlay.style.cssText = "";
        }
      }
    },
    async cropImage() {
      this.canEdit = false;

      console.log(this.$refs.cropper);
      console.log(this.croppr.getValue());

      const croppedImage = this.$refs.cropper
        .setData(this.croppr.getValue())
        .getCroppedCanvas({ width: 1024, height: 1024 })
        .toDataURL("image/jpeg", 0.5);

      const originalImage = await this.resizeOriginalImage(this.croppingImage);

      console.log(croppedImage);
      console.log(originalImage);

      try {
        let response = await gamesApi.photoFrameUpload({
          original_image: originalImage,
          cropped_image: croppedImage,
          mask_image: this.selectedMask,
        });
        console.log(response);

        this.$swal("成功", "獲得一張有框框的圖啦！", "success");
        this.displayImage = response.data.data;
        this.resetCropping();
      } catch (error) {
        console.error(error);
      } finally {
        this.canEdit = false;
      }
    },
    // 把原圖縮小
    async resizeOriginalImage(imageBase64) {
      // create an off-screen canvas
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      let originalImage = await this.base64toImage(imageBase64);

      let width = originalImage.width;
      let height = originalImage.height;

      if (width > 1400) {
        let ratio = 1400 / width;
        width = width * ratio;
        height = height * ratio;
      }

      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx.drawImage(originalImage, 0, 0, width, height);

      return canvas.toDataURL("image/jpeg", 0.6);
    },
    async base64toImage(base64) {
      return new Promise((resolve) => {
        var image = new Image();
        image.onload = function () {
          resolve(image);
        };
        image.src = base64;
      });
    },
    async resetCropping() {
      this.croppingImage = null;
      if (this.croppr) {
        this.croppr.destroy();
        this.croppr = null;
      }
      await this.$nextTick();
    },
    openFileSelector() {
      this.$refs.input.click();
    },
    openCameraSelector() {
      this.$refs.camera.click();
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;
    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }
  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}

.s-file-input {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
}

.s-btn-bg-secondary {
  background-color: #a1a1a1 !important;
}
</style>
