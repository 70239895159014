import https from "./https"
import store from "@/store"

const organization = store.state.liffGeneral.orgCode;

const games = {
  async photoFrameUpload(params) {
    return https.post(`${organization}/liff/games/photo-frame-upload`, params)
  },
  async getEvent(params) {
    return https.get(`${organization}/liff/games/event`, { params });
  }
}

export default games
